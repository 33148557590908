<template>
  <div class="news-info">
    <div class="nav">
      <div class="container">
        <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
          <el-menu-item index="1">平台最新通知</el-menu-item>
          <el-menu-item index="2">行业动态</el-menu-item>
          <el-menu-item index="3">政策文件 </el-menu-item>
        </el-menu>
        <Serach size="mini" :serchValue="serchValue" style="height: 40px;width: 359px;" @serch="serch"></Serach>
      </div>
    </div>
    <div class="content container">
      <ul class="list-data" v-loading="loading">
        <li v-for="item of listData" :key="item.id" @click="goDetail(item)">
          <div class="title">
            <h3>{{ item.title }}</h3>
          </div>
          <div class="content">{{ delHtmlTag(item.content) }}</div>
          <div class="time">
            <span>
              {{ item.createTime|filterTimeNoHms }}
            </span>
            <span class="more-icon">
              <span class="more">查看详情</span>
              <span class="svghover"></span>
              <span class="normal"></span>
            </span>
          </div>
        </li>
      </ul>
      <div class="no-data" v-if="total === 0 && listData.length === 0">
        <img src="@/assets/images/new-ui/gy_pic_ss_none@2x.png" alt="">
        <div>对不起，搜索无结果</div>
      </div>
      <el-pagination
        style="margin-top: 0"
        v-if="total >= 12"
        :current-page="currentPage"
        :page-size="pageSize"
        :total="total"
        @current-change="currentChange"
        :page-sizes="[12]"
        layout="prev, pager, next"
        background
        prev-text="上一页"
        next-text="下一页"
      ></el-pagination>
      <!-- <p class="nomore" v-show="total < 13 && total > 0">没有了~</p> -->
    </div>
  </div>
</template>

<script>
import Serach from '@/components/Layout/SearchInput.vue'
import indexApi from "@/api/index.js";

export default {
  components: {
    Serach
  },
  filters: {
    filterElectronicType(val) {
      if (val == 0) return "非电子标";
      if (val == 1) return "电子标";
    },
    filterTime(val) {
      return moment(val).format("YYYY-MM-DD HH:mm:ss");
    },
    filterTimeNoHms(val) {
      return moment(val).format("YYYY-MM-DD");
    },
  },
  watch: {
    "$route.query"(newVal, oldVal) {
      this.activeIndex = newVal.type ?? this.activeIndex
      this.renderList()
    }
  },
  data() {
    return {
      loading: false,
      activeIndex: '1',
      serchValue: '',
      currentPage: 1,
      pageSize: 12,
      total: 0,
      listData: [],
    }
  },
  created() {
    if (this.$route.query.type) {
      this.activeIndex = this.$route.query.type
    }
    this.renderList(this.activeIndex)
  },
  methods: {
    delHtmlTag (val) {
      if (val) {
        return val.replace(/<[^>]+>|&[nbsp;]+/g,"")
      }
      return ''
    },
    async clickNum(id) {
      await indexApi.imageClick({id})
    },
    goDetail(item) {
      this.$router.push({
        name: '最新资讯详情',
        query: {
          id: item.id
        }
      })
      this.clickNum(item.id)
    },
    async renderList() {
      try {
        this.loading = true
        let { code, data, message } = await indexApi.findInformOrNewsList({
          page: this.currentPage,
          size: this.pageSize,
          module: this.activeIndex, //(1.通知公告 2.最新动态 3政策法规)
          sort: "asc",
          sortFiled: "sort",
          title: this.serchValue,
          contentType: '1'
        });
        if (code === 200) {
          this.listData = [...data.records]
          this.total = data.total;
        }
      } finally {
        this.loading = false
      }
    },
    // 分页相关函数
    currentChange(val) {
      this.currentPage = val;
      this.renderList();
    },
    handleSelect(key) {
      this.currentPage = 1;
      this.activeIndex = key
      // this.renderList()
      this.$router.push({
        query: {
          ...this.$route.query,
          type: key
        }
      })
    },
    serch(val) {
      this.currentPage = 1;
      this.serchValue = val
      this.renderList()
    }
  }
}
</script>

<style lang="less" scoped>
  .news-info {
    margin-bottom: 32px;
    .no-data {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #C3C3C3;
      margin-bottom: 32px;
    }
    .nav {
      background: linear-gradient(180deg, #F3F5F7 0%, #FFFFFF 100%);
      box-shadow: 0px 8px 16px 1px rgba(0,0,0,0.05);
      height: 60px;
      .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        .el-menu-demo {
          border-bottom: none;
          background: none;
          .el-menu-item {
            padding: 0;
            font-size: 18px;
            margin-right: 56px;
            color: #999;
            border-bottom: none !important;
            transition: background-color .3s,color .3s;
            &:hover {
              color: #F36E31;
              background: none;
              border-bottom: none !important;
              font-weight: bold;
            }
          }
          .is-active {
            color: #333;
            border-bottom: none;
            position: relative;
            color: #F36E31;
            font-weight: bold;
            background: none;
            
          }
        }
      }
    }
    .list-data {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 40px;
      &::after {
        content: '';
        height: 0;
        width: 374px;
      }
      li {
        width: 374px;
        height: 138px;
        margin-bottom: 44px;
        cursor: pointer;
        &:hover {
          .title {
            margin-bottom: 6px;
          }
          .title::after {
            content: '';
            position: absolute;
            width: 374px;
            height: 1px;
            background-color: #F36E31;
            bottom: -1px;
            left: 0;
          }
          .more-icon {
            margin-right: 0px !important;
          }
          .more {
            opacity: 1 !important;
            margin-left: 20px;
          }
          .normal {
            width: 0px !important;
          }
          .svghover {
            opacity: 1 !important;
          }
        }
        .title {
          position: relative;
          padding-bottom: 12px;
          margin-bottom: 12px;
          border-bottom: 1px solid #DEDEDE;
          transition: all 0.4s ease;
          &::after {
            content: '';
            position: absolute;
            width: 0px;
            height: 1px;
            background-color: #F36E31;
            bottom: -1px;
            left: 0;
            transition: width 0.4s ease-in-out;
          }
          h3 {
            color: #333;
            font-size: 16px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .content {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          font-size: 14px;
          color: #999;
          line-height: 24px;
          min-height: 48px;
          margin-bottom: 16px;
        }
        .time {
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #999;
          font-size: 14px;
          position: relative;
          .more-icon {
            margin-right: 10px;
            position: relative;
            transition: all 0.3s;
          }
          .more {
            color: #F36E31;
            opacity: 0;
            transition: opacity 0.3s;
            font-size: 14px;
            margin-right: 4px;
          }
          .svghover {
            opacity: 0;
            width: 24px;
            height: 24px;
            transition: all 0.4s;
            background-image: url(~@/assets/images/new-ui/ic_xq_hover.svg);
            display: inline-block;
          }
          .normal {
            transition: all 0.4s;
            position: absolute;
            right: 0;
            top: 0;
            width: 24px;
            height: 24px;
            background-image: url(~@/assets/images/new-ui/ic_xq_normal.svg);
            background-position: right;
          }
        }
      }
    }
  }
</style>